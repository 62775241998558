import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm, ValidationErrors } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { VaisFormsService } from '../../services';
import { ModalComponent } from '../../../shared/components';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { ProductModal } from 'src/app/shared/models/product-modal.model';
import { matchField } from 'src/app/validators/match-field.validator';
import { Product, PRODUCT_DEFAULTS } from '../../../shared/models/product.model';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { MatDialog, MatRadioButton, MatRadioChange } from '@angular/material';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { take } from 'rxjs/operators';
import { VaisModalForms } from 'src/app/shared/models/vais-modal-forms.enum';

@Component({
  selector: 'app-vais-product-selection',
  templateUrl: './vais-product-selection.component.html',
  styleUrls: ['./vais-product-selection.component.css']
})

export class VaisProductSelectionComponent extends FormCanDeactivate implements OnInit {
  @Output() nextEvent = new EventEmitter<void>();
  @Output() previousEvent = new EventEmitter<void>();
  @ViewChild('formTwo', { static: false }) form: NgForm;

  provider: Provider;

  productSelectionForm: FormGroup;
  previouslySavedForm: FormGroup;
  previousProductDetails: Product;

  visibleProducts: Product[];
  isFootnoteVisible = false;
  showAsModal: boolean;

  filterMode = this.appConfig.config.filterMode;

  config = this.appConfig.config;

  stepTitle: string;
  nextStepTitle: string;

  constructor(
    private productSelectionFormBuilder: FormBuilder,
    private vaisFormsService: VaisFormsService,
    private modal: NgbModal,
    private matDialog: MatDialog,
    private appConfig: AppConfig,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.provider = this.vaisFormsService.provider;
    this.stepTitle = this.vaisFormsService.steps.vaisProductSelection;
    this.nextStepTitle = this.vaisFormsService.steps.vaisMemberInformation;
  }

  get selectedProductDetails(): Product {
    return !this.visibleProducts ? PRODUCT_DEFAULTS :
      this.visibleProducts.find((products: Product) => products.id === this.productSelectionForm.value.selectedProduct) || PRODUCT_DEFAULTS;
  }

  get selectedDeviceType() {
    const selectedDevice = this.selectedProductDetails;
    return this.getDeviceType(selectedDevice);
  }

  ngOnInit() {
    this.initializeProductSelectionForm();

    this.previousProductDetails = this.vaisFormsService.productInfo$.value;

    this.vaisFormsService.showAsModal$.subscribe(showAsModal => {
      this.showAsModal = showAsModal;
    });

    this.vaisFormsService.productSelectionForm$.subscribe(productInfo => {
      this.previouslySavedForm = productInfo;
      this.productSelectionForm.patchValue(productInfo.getRawValue());
      this.showApplicableProducts();
      this.productSelectionForm.updateValueAndValidity();
    });
  }

  showApplicableProducts() {
    this.productSelectionForm.controls.selectedProduct.markAsUntouched();
    this.productSelectionForm.controls.phoneNumber.markAsUntouched();
    this.productSelectionForm.controls.confirmPhoneNumber.markAsUntouched();

    switch(this.productSelectionForm.value.serviceType) {
      case this.vaisFormsService.serviceTypes.gpsMobile:
        this.visibleProducts = this.vaisFormsService.gpsDevices(this.productSelectionForm.value.showAwayService);
        break;
      case this.vaisFormsService.serviceTypes.inHome:
        switch (this.productSelectionForm.value.phoneType) {
          case this.vaisFormsService.phoneTypes.landline:
            this.visibleProducts = this.vaisFormsService.landlineDevices();
            break;
          case this.vaisFormsService.phoneTypes.cell:
            this.visibleProducts = this.vaisFormsService.cellDevices();
            break;
          default:
            this.visibleProducts = this.vaisFormsService.inHomeDevices();
        }
        break;
      default:
        this.visibleProducts = this.filterMode ? this.vaisFormsService.allDevices() : [];
    }

    if (!this.visibleProducts.some(product => product.id === this.productSelectionForm.value.selectedProduct)) {
      this.productSelectionForm.controls.selectedProduct.setValue(null);
    }
  }

  productSelected(event: MatRadioChange) {
    if (event.source instanceof MatRadioButton) {
      const selectedProductId: number = event.value;
      const selectedProduct: Product = this.visibleProducts.find((products: Product) => products.id === selectedProductId);
      this.vaisFormsService.productInfo$.next(selectedProduct);
    }
  }

  openProdModal(
    productid: number,
    serviceType: boolean,
    isReplacement: boolean,
    image?: string,
    prodDetails?: ProductModal
  ) {
    const productModal = this.modal.open(ModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
    productModal.componentInstance.isProdModal = true;
    productModal.componentInstance.modalData = (prodDetails === undefined) ? '' : prodDetails[0];
    productModal.componentInstance.prodImage = image;
    productModal.componentInstance.isCell = serviceType;
    productModal.componentInstance.prodID = productid;
    productModal.componentInstance.isReplace = isReplacement;
  }

  getDeviceType(product: Product) {
    if (!product || !product.deviceType) {
      return null;
    }

    switch (product.deviceType.toUpperCase()) {
      case this.vaisFormsService.deviceTypes.cell.toUpperCase():
        return this.vaisFormsService.deviceTypes.cell;
      case this.vaisFormsService.deviceTypes.landline.toUpperCase():
        return this.vaisFormsService.deviceTypes.landline;
      case this.vaisFormsService.deviceTypes.gps.toUpperCase():
        return this.vaisFormsService.deviceTypes.gps;
    }
  }

  toggleFootnote(state: boolean = null) {
    if (state === null) {
      this.isFootnoteVisible = !this.isFootnoteVisible;
    } else {
      this.isFootnoteVisible = state;
    }
  }

  goToPreviousStep() {
    this.previousEvent.emit();
  }

  goToNextStep() {
    this.productSelectionForm.updateValueAndValidity();
    this.productSelectionForm.markAllAsTouched();
    this.changeDetectorRef.detectChanges();

    if (!this.productSelectionForm.valid) {
      this.vaisFormsService.scrollToInvalidControl(this.productSelectionForm);
      return;
    }

    this.vaisFormsService.productSelectionForm$.next(this.productSelectionForm);
    this.vaisFormsService.calculatePrice();

    this.vaisFormsService.memberInformationForm$
      .pipe(take(1))
      .subscribe((memberInformationForm: FormGroup) => {
        if (memberInformationForm && memberInformationForm.controls && memberInformationForm.controls.phoneNumber) {
          const phoneNumber = this.productSelectionForm.value.phoneNumber;
          memberInformationForm.controls.phoneNumber.setValue(phoneNumber);
          this.vaisFormsService.memberInformationForm$.next(memberInformationForm);
        }
      });

    if (this.showAsModal) {
      this.updateFromModal();
    } else {
      this.nextEvent.emit();
    }
  }

  private initializeProductSelectionForm() {
    this.productSelectionForm = this.productSelectionFormBuilder.group({
      serviceType: [this.vaisFormsService.serviceTypes.all],
      phoneType: [this.vaisFormsService.phoneTypes.all],
      phoneNumber: ['', [Validators.required, Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]],
      confirmPhoneNumber: ['', Validators.required],
      selectedProduct: [null, Validators.required],
      showAwayService: [this.provider.disableAwayService ? false : null],
      didUserAgreeToAwayServiceTerms: [false],
      isFallDetectionRequested: [false],
      isHomeInstallationRequested: [false]
    }, {
      validator: [
        matchField('phoneNumber', 'confirmPhoneNumber'),
        (formGroup: FormGroup): ValidationErrors | null =>
          formGroup && this.selectedProductDetails.hasAwayService && !formGroup.value.didUserAgreeToAwayServiceTerms ?
            { awayServiceConsentRequired: true } : null
      ]
    });

    this.productSelectionForm.controls.selectedProduct.valueChanges.subscribe(() => {
      this.productSelectionForm.controls.didUserAgreeToAwayServiceTerms.setValue(false);
      this.productSelectionForm.controls.isFallDetectionRequested.setValue(false);
      this.productSelectionForm.controls.isHomeInstallationRequested.setValue(false);
    });
  }

  private updateFromModal() {
    if (this.previousProductDetails.hasAwayService === this.selectedProductDetails.hasAwayService) {
      this.modal.dismissAll({return: 'value'});
      //this.matDialogRef.close({return: 'value'});
    } else {
      if (this.selectedProductDetails.hasAwayService) {
        const title = 'Away Service (Wandering) Contact Is Required';
        // eslint-disable-next-line max-len
        const message = 'Please add an emergency contact with an Away Service (Wandering) Contact role in the Emergency Contact Information section.';
        this.openDialogBox(title, message);
      } else {
        const title = 'Away Service (Wandering) Contact Roles Will Be Removed';
        const message = 'The Away Service (Wandering) Contact role(s) will be removed in the Emergency Contact Information section. ' +
          'Away Service (Wandering) Contacts are only needed when you select a product with Away Service (Wandering).';
        this.openDialogBox(title, message);
      }
    }
  }

  private openDialogBox(title: string, message: string) {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      width: '800px',
      autoFocus: false,
      disableClose: true,
      data: { title, message }
    });

    const dialogSubmitSubscription = dialogRef.componentInstance.confirm
      .subscribe(() => {
        dialogSubmitSubscription.unsubscribe();
        this.modal.dismissAll({newForm: VaisModalForms.emergencyContactInfo});
      });
  }
}
