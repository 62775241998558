import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import products from '../../assets/json/products.json';
import { PayerConfig } from './model/payerconfig.model';
import { PayerConfigService } from './service/payerconfig.service';
import { Product } from '../shared/models/product.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConfig } from '../shared/services/app-config.service';

@Component({
  selector: 'app-config-manager',
  templateUrl: './config-manager.component.html',
  styleUrls: ['./config-manager.component.css']
})
export class ConfigManagerComponent implements OnInit {
  isFireFox: boolean;
  isSafari: boolean;
  isIEorEdge: boolean;
  isChrome: boolean;
  isOpera: boolean;
  isLoggedIn: boolean;
  landlineproducts: Product[];
  cellularproducts: Product[];
  showContent = true;
  llproduct1: string;
  llproduct2: string;
  llproduct3: string;
  llproduct1Id: string;
  llproduct2Id: string;
  llproduct3Id: string;
  llproduct1Title: string;
  llproduct2Title: string;
  llproduct3Title: string;
  cellproduct1: string;
  cellproduct2: string;
  cellproduct3: string;
  cellproduct1Id: string;
  cellproduct2Id: string;
  cellproduct3Id: string;
  cellproduct1Title: string;
  cellproduct2Title: string;
  cellproduct3Title: string;

  constructor(
    private payerConfigService: PayerConfigService,
    private router: Router,
    private appConfig: AppConfig
  ) {
    this.isFireFox = /firefox/i.test(navigator.userAgent);
    this.isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    this.isIEorEdge = /msie\s|trident\/|edge\//.test(window.navigator.userAgent);
    this.isChrome = /Google Inc/.test(navigator.vendor);
    this.isOpera = /OPR/.test(navigator.userAgent);
  }

  ngOnInit() {
    this.payerConfigService.currentState$.subscribe(message => this.isLoggedIn = message);
    this.landlineproducts = products.line;
    this.cellularproducts = products.cell;
    this.llproduct1 = JSON.stringify(this.landlineproducts[0].prodNo).replace(/['"]+/g, '');
    this.llproduct2 = JSON.stringify(this.landlineproducts[1].prodNo).replace(/['"]+/g, '');
    this.llproduct3 = JSON.stringify(this.landlineproducts[2].prodNo).replace(/['"]+/g, '');
    this.llproduct1Id = JSON.stringify(this.landlineproducts[0].id).replace(/['"]+/g, '');
    this.llproduct2Id = JSON.stringify(this.landlineproducts[1].id).replace(/['"]+/g, '');
    this.llproduct3Id = JSON.stringify(this.landlineproducts[2].id).replace(/['"]+/g, '');
    this.llproduct1Title = JSON.stringify(this.landlineproducts[0].prodTitle).replace(/['"]+/g, '');
    this.llproduct2Title = JSON.stringify(this.landlineproducts[1].prodTitle).replace(/['"]+/g, '');
    this.llproduct3Title = JSON.stringify(this.landlineproducts[2].prodTitle).replace(/['"]+/g, '');
    this.cellproduct1 = JSON.stringify(this.cellularproducts[0].prodNo).replace(/['"]+/g, '');
    this.cellproduct2 = JSON.stringify(this.cellularproducts[1].prodNo).replace(/['"]+/g, '');
    this.cellproduct3 = JSON.stringify(this.cellularproducts[2].prodNo).replace(/['"]+/g, '');
    this.cellproduct1Id = JSON.stringify(this.cellularproducts[0].id).replace(/['"]+/g, '');
    this.cellproduct2Id = JSON.stringify(this.cellularproducts[1].id).replace(/['"]+/g, '');
    this.cellproduct3Id = JSON.stringify(this.cellularproducts[2].id).replace(/['"]+/g, '');
    this.cellproduct1Title = JSON.stringify(this.cellularproducts[0].prodTitle).replace(/['"]+/g, '');
    this.cellproduct2Title = JSON.stringify(this.cellularproducts[1].prodTitle).replace(/['"]+/g, '');
    this.cellproduct3Title = JSON.stringify(this.cellularproducts[2].prodTitle).replace(/['"]+/g, '');
  }

  onSubmit(event) {
    const payerConfig: PayerConfig = {
      name: event.currentTarget.name === null ? '' : event.currentTarget.name.value,
      companyName: event.currentTarget.companyName === null ? '' : event.currentTarget.companyName.value,
      landingPageUrl: event.currentTarget.landingPageUrl === null ? '' : event.currentTarget.landingPageUrl.value,
      useLogo: event.currentTarget.useLogo === 'yes' ? true : false,
      logoLocationUrl: event.currentTarget.logoLocationUrl === null ? '' : event.currentTarget.logoLocationUrl.value,
      helpWorkingHours: (event.currentTarget.helpWorkingHours1 === null || event.currentTarget.helpWorkingHours2 === null) ? '' :
        this.formatAMPM(event.currentTarget.helpWorkingHours1.value) + ' to ' +
        this.formatAMPM(event.currentTarget.helpWorkingHours2.value),
      helpPhoneNumber: event.currentTarget.helpPhoneNumber === null ? '' : event.currentTarget.helpPhoneNumber.value,
      cellularProducts: event.currentTarget.cellularProducts === null ? [] :
        this.createProductsArray(event.currentTarget.cellularProducts.value),
      landlineProducts: event.currentTarget.landlineProducts === null ? [] :
        this.createProductsArray(event.currentTarget.landlineProducts.value)
    };

    this.payerConfigService.savePayerConfig(payerConfig).subscribe(() => {
    }, (error: unknown) => {
      if (this.appConfig.config.debug && error instanceof HttpErrorResponse) {
        console.log(error);
        console.log(`Error: ${error.message}`);
      }
    });
  }

  navigateToLogin() {
    this.router.navigateByUrl(`/configmanager-login`);
  }

  private formatAMPM(strInput: string): string {
    const res = strInput.split(':');
    let hours = parseInt(res[0], 10);
    const minutes = res[1];
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  private createProductsArray(strInput: string): any[] {
    const elems = strInput.split(',');
    const productsArray: any[] = new Array();
    for (const item of Object.keys(elems)) {
      const obj = {
        id: item
      };
      productsArray.push(obj);
    }
    return productsArray;
  }
}
