import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { PortalsService } from '../../services/portals.service';
import { Router } from '@angular/router';
import { FormsService } from 'src/app/enrollment-forms/services';
import { VaisFormsService } from 'src/app/vais/services';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.css']
})
export class NavigationMenuComponent {
  isLoggedIn = false;
  showDropdown = false;
  portals: { name: string; url: string }[] = [];

  constructor(private authService: AuthService,
    private portalsService: PortalsService,
    private router: Router,
    private formsService: FormsService,
    private vaisFormsService: VaisFormsService
  ) {
    this.portals = this.portalsService.portals;
    this.authService.isLoggedIn$().subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  navigate(url: string) {
    this.router.navigateByUrl(url).then(() => {
      this.formsService.setDefaultProvider();
      if (url.includes('vais')) {
        this.vaisFormsService.initialize();
      }
      location.reload();
    });
    this.showDropdown = false;
  }
}
