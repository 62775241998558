import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, NgForm, Validators } from '@angular/forms';
import { VaisFormsService } from '../../services';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { SearchService } from 'src/app/eligibility-search/service/search.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { couponCodeValidator } from 'src/app/validators/coupon-code.validator';
import { FormsService } from 'src/app/enrollment-forms/services';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vais-coupon-verification',
  templateUrl: './vais-coupon-verification.component.html',
  styleUrls: ['./vais-coupon-verification.component.css']
})

export class VaisCouponVerificationComponent extends FormCanDeactivate implements OnInit {
  @Output() nextEvent = new EventEmitter<number>();
  @ViewChild('formOne', { static: false }) form: NgForm;

  couponVerificationForm: FormGroup;
  provider: Provider;

  isSubmitting = false;
  hasExistingAccount = false;
  unknownError = false;

  captchaKey: string = this.appConfig.config.captcha;
  isRepLoggedIn = false;

  minDate: NgbDateStruct = { year: 1900, month: 1, day: 1};
  maxDate: NgbDateStruct = { year: new Date().getFullYear() - 1, month: 12, day: 31 };
  startDate: NgbDateStruct = { year: new Date().getFullYear() - 50, month: 12, day: 31 };

  stepTitle: string;
  nextStepTitle: string;

  constructor(
    private authService: AuthService,
    private vaisFormsService: VaisFormsService,
    private formsService: FormsService,
    private couponVerificationFormBuilder: FormBuilder,
    private searchService: SearchService,
    private appConfig: AppConfig,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.provider = this.vaisFormsService.provider;
    this.stepTitle = this.vaisFormsService.steps.vaisCouponVerification;
    this.nextStepTitle = this.vaisFormsService.steps.vaisProductSelection;
  }

  ngOnInit() {
    this.authService.isLoggedIn$().subscribe(isRepLoggedIn => {
      this.isRepLoggedIn = isRepLoggedIn;
    });

    this.vaisFormsService.clearForms();

    const selectedCustomer = this.searchService.getSelectedCustomer();
    const dataFromEnrollment = this.formsService.getVerificationDataForVais();

    const namePattern = /^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/;

    this.couponVerificationForm = this.couponVerificationFormBuilder.group({
      lastName: [
        dataFromEnrollment ? dataFromEnrollment.lastName :
          selectedCustomer ? selectedCustomer.lastName.toUpperCase() : '',
        {
          validators: [
            Validators.required,
            Validators.pattern(namePattern),
            Validators.maxLength(40)
          ]
        }],
      firstName: [
        dataFromEnrollment ? dataFromEnrollment.firstName :
          selectedCustomer ? selectedCustomer.firstName.toUpperCase() : '', {
          validators: [
            Validators.required,
            Validators.pattern(namePattern),
            Validators.maxLength(40)
          ]
        }],
      dob: [
        dataFromEnrollment ? dataFromEnrollment.dob :
          selectedCustomer ? this.stringToNgbDateStruct(selectedCustomer.mbrDob.toString()) : null,
        Validators.required
      ],
      zipCode: [
        dataFromEnrollment ? dataFromEnrollment.zipCode :
          selectedCustomer ? selectedCustomer.zip : '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]{5}$/)
        ]
      ],
      phoneNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{3}-\d{3}-\d{4}$/),
          Validators.minLength(12),
          Validators.maxLength(12)
        ]
      ],
      couponCode: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[A-Za-z0-9]+$/),
          couponCodeValidator(this.provider)
        ]
      ],
      hasServiceAlready: [
        {
          value: this.isRepLoggedIn ? 'no' : null,
          disabled: this.isRepLoggedIn
        },
        [
          Validators.required,
          Validators.pattern('no')
        ]
      ],
      memberRecaptcha: ['', !this.isRepLoggedIn ? Validators.required : null]
    });
  }

  returnTocouponVerificationForm() {
    this.hasExistingAccount = false;
    this.unknownError = false;
  }

  goToNextStep() {
    this.couponVerificationForm.markAllAsTouched();
    this.changeDetectorRef.detectChanges();

    if (!this.couponVerificationForm.valid) {
      if (this.appConfig.config.debug) {
        console.log(this.vaisFormsService.debugAllValidators(this.couponVerificationForm));
      }
      this.vaisFormsService.scrollToInvalidControl(this.couponVerificationForm);
      return;
    }

    this.isSubmitting = true;
    this.checkIfEligible$().subscribe((isEligible) => {
      this.isSubmitting = false;
      if (isEligible) {
        this.vaisFormsService.couponVerificationForm$.next(this.couponVerificationForm);
        this.nextEvent.emit();
      }
    });
  }

  private checkIfEligible$(): Observable<boolean> {
    const monthStr = (this.couponVerificationForm.value.dob.month <= 9 ? '0' : '') + (this.couponVerificationForm.value.dob.month);
    const dayStr = (this.couponVerificationForm.value.dob.day <= 9 ? '0' : '') + (this.couponVerificationForm.value.dob.day);
    const dobStr = this.couponVerificationForm.value.dob.year + '-' + monthStr + '-' + dayStr;

    return this.vaisFormsService.checkIfEligible$(
      this.couponVerificationForm.value.firstName,
      this.couponVerificationForm.value.lastName,
      dobStr,
      this.couponVerificationForm.value.zipCode,
      String(this.couponVerificationForm.value.phoneNumber).replace(/-/g, '')
    ).pipe(map((eligibilityResponse: boolean) => eligibilityResponse),
      catchError((errorResponse: unknown) => {
      if (errorResponse instanceof HttpErrorResponse && errorResponse.status === 409) {
        this.hasExistingAccount = true;
      } else {
        if (this.appConfig.config.debug) {
          console.log(errorResponse);
        }
        this.unknownError = true;
      }

      return of(false);
    }));
  }

  private stringToNgbDateStruct(dateString: string): NgbDateStruct {
    return this.dateToNgbDateStruct(new Date(dateString));
  }

  private dateToNgbDateStruct(date: Date): NgbDateStruct {
    return {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate()
    };
  }
}
