import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, NgForm, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import languages from '../../../../assets/json/vaislanguages.json';
import phoneTypes from '../../../../assets/json/phonetype.json';
import relations from '../../../../assets/json/relations.json';
import roles from '../../../../assets/json/roles.json';

import { VaisFormsService } from '../../services';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Role } from 'src/app/shared/models/role.model';
import { Language } from 'src/app/shared/models/language.model';
import { PhoneType } from 'src/app/shared/models/phonetype.model';
import { Relationship } from 'src/app/shared/models/relationship.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';

const MAX_EMERGENCY_CONTACT_FORMS = 3;

@Component({
  selector: 'app-vais-emergency-contact-info',
  templateUrl: './vais-emergency-contact-info.component.html',
  styleUrls: ['./vais-emergency-contact-info.component.css']
})

export class VaisEmergencyContactInfoComponent extends FormCanDeactivate implements OnInit {
  @Output() nextEvent = new EventEmitter<void>();
  @Output() previousEvent = new EventEmitter<void>();
  @ViewChild('formFour', { static: false }) form: NgForm;

  emergencyContactInfoForm: FormGroup;
  languages: Language[];
  relationships: Relationship[];
  phoneTypes: PhoneType[];
  roles: Role[];
  showAsModal: boolean;
  hasResponder: boolean;
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isAwayContactRequired$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isEmergencyContactOptional$: Observable<boolean>;
  math = Math;

  stringifyIfDevelopment = this.vaisFormsService.stringifyIfDevelopment;

  stepTitle: string;
  nextStepTitle: string;

  constructor(
    private vaisFormsService: VaisFormsService,
    private modal: NgbModal,
    private authService: AuthService,
    private appConfig: AppConfig,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.stepTitle = this.vaisFormsService.steps.vaisEmergencyContactInfo;
    this.nextStepTitle = this.vaisFormsService.steps.vaisReviewOrder;
  }

  get canAddMoreEmergencyContacts(): boolean {
    const emergencyContactInfoForm = this.getemergencyContactInfoForms(this.emergencyContactInfoForm);
    return emergencyContactInfoForm && emergencyContactInfoForm.length < MAX_EMERGENCY_CONTACT_FORMS;
  }

  ngOnInit() {
    this.authService.isLoggedIn$()
      .pipe(take(1))
      .subscribe(isLoggedIn => {
        this.isLoggedIn$.next(isLoggedIn);
      });

    this.emergencyContactInfoForm = new FormGroup({
      details: new FormArray([]),
    });

    this.vaisFormsService.showAsModal$.subscribe(showAsModal => {
      this.showAsModal = showAsModal;
    });

    if (this.showAsModal) {
      this.vaisFormsService.emergencyContactInfoForm$.subscribe(
        (emergencyContactModalResponse: FormGroup) => {
          if (emergencyContactModalResponse) {
            this.emergencyContactInfoForm.controls.details = emergencyContactModalResponse.controls.details;
          }
        });
    }

    this.languages = languages;
    this.relationships = relations;
    this.phoneTypes = phoneTypes;
    this.roles = [...roles];

    this.vaisFormsService.productSelectionForm$.subscribe(productInfo => {
      if (productInfo && productInfo.value.didUserAgreeToAwayServiceTerms) {
        this.isAwayContactRequired$.next(true);
      } else {
        this.isAwayContactRequired$.next(false);
      }
    });

    this.isAwayContactRequired$.subscribe(isAwayContactRequired => {
      if (isAwayContactRequired && !this.vaisFormsService.provider.disableAwayService) {
        this.roles = [{ name: this.vaisFormsService.contactTypes.awayServiceContact, id: 3 }, ...roles];
      } else {
        this.roles = [...roles];
        this.deleteAwayContacts();
      }
      this.hasAwayContactIfRequired();
    });

    this.isEmergencyContactOptional$ = this.isAwayContactRequired$.pipe(
      map((isAwayContactRequired) => !isAwayContactRequired)
    );

    this.isEmergencyContactOptional$.subscribe(isEmergencyContactOptional => {
      if (isEmergencyContactOptional) {
        this.emergencyContactInfoFormIfUntouched();
      } else {
        const emergencyContactInfoForms = this.getemergencyContactInfoForms(this.emergencyContactInfoForm);
        if (emergencyContactInfoForms && emergencyContactInfoForms.length === 0) {
          this.addEmergencyContact();
        }
      }
    });

    this.hasResponder = true;
    this.checkEmergencyContactSelections();
  }

  getemergencyContactInfoForms(formEmergencyContact) {
    return formEmergencyContact &&
      formEmergencyContact.controls &&
      formEmergencyContact.controls.details &&
      formEmergencyContact.controls.details.controls;
  }

  getPhoneDetails(formPhone) {
    return formPhone.controls.phoneDetails.controls;
  }

  // If the responder is already selected set to true do not show the error
  roleSelection() {
    if (this.emergencyContactInfoForm.value.details !== null) {
      this.hasAwayContactIfRequired();
    }
  }

  addEmergencyContact() {
    const emergencyContactInfoForms = this.getemergencyContactInfoForms(this.emergencyContactInfoForm);
    if (emergencyContactInfoForms) {
      const numemergencyContactInfoForms = emergencyContactInfoForms.length;
      if (numemergencyContactInfoForms < MAX_EMERGENCY_CONTACT_FORMS) {
        const control = this.emergencyContactInfoForm.controls.details as FormArray;
        control.push(this.initDetails());
      }
    }
  }

  removeEmergencyContact(index: number) {
    const control = this.emergencyContactInfoForm.controls.details as FormArray;
    control.removeAt(index);
  }

  addPhone(index: number) {
    const control = this.emergencyContactInfoForm.get(['details', index, 'phoneDetails']) as FormArray;
    control.push(this.initPhoneDetails());
  }

  removePhone(i: number, j: number) {
    const control = this.emergencyContactInfoForm.get(['details', i, 'phoneDetails']) as FormArray;
    control.removeAt(j);
  }

  goToPreviousStep() {
    this.previousEvent.emit();
  }

  goToNextStep() {
    this.emergencyContactInfoForm.markAllAsTouched();
    this.emergencyContactInfoForm.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();

    if (!this.emergencyContactInfoForm.valid) {
      if (this.appConfig.config.debug) {
        console.log(this.vaisFormsService.debugAllValidators(this.emergencyContactInfoForm));
      }
      this.vaisFormsService.scrollToInvalidControl(this.emergencyContactInfoForm);
      return;
    }

    if (!this.hasAwayContactIfRequired()) {
      return;
    }

    this.vaisFormsService.emergencyContactInfoForm$.next(this.emergencyContactInfoForm);

    if (this.showAsModal) {
      this.modal.dismissAll();
    } else {
      this.nextEvent.emit();
    }
  }

  private checkEmergencyContactSelections() {
    const emergencyContactsFormGroup = this.emergencyContactInfoForm.controls.details as FormGroup;
    if (!emergencyContactsFormGroup.controls[0]) {
      return;
    }

    const firstEmergencyContactInfoFormGroup = emergencyContactsFormGroup.controls[0] as FormGroup;
    if (!firstEmergencyContactInfoFormGroup) {
      return;
    }

    if (firstEmergencyContactInfoFormGroup.value.language === null) {
      firstEmergencyContactInfoFormGroup.controls.language.setValue('');
    }

    if (firstEmergencyContactInfoFormGroup.value.role === null) {
      firstEmergencyContactInfoFormGroup.controls.role.setValue('');
    }

    if (firstEmergencyContactInfoFormGroup.value.relationship === null) {
      firstEmergencyContactInfoFormGroup.controls.relationship.setValue('');
    }

    const firstEmergencyContactPhoneDetailsGroup = firstEmergencyContactInfoFormGroup.controls.phoneDetails as FormArray;
    const firstEmergencyContactFirstPhoneDetailsGroup = firstEmergencyContactPhoneDetailsGroup.controls[0] as FormGroup;

    if (firstEmergencyContactFirstPhoneDetailsGroup.value.carePhoneType === null) {
      firstEmergencyContactFirstPhoneDetailsGroup.controls.carePhoneType.setValue('');
    }
  }

  private initDetails() {
    return new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.maxLength(40)
      ]),
      middleName: new FormControl('', [
        Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.maxLength(40)
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
        Validators.maxLength(40)
      ]),
      phoneDetails: new FormArray([
        this.initPhoneDetails(),
      ]),
      email: new FormControl('', [Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]),
      emailMatch: new FormControl(''),
      relationship: new FormControl('', Validators.required),
      role: new FormControl([], Validators.required),
      language: new FormControl('', Validators.required)
    }, [
      this.emailMatchValidator,
      this.awayContactMobileValidator,
      this.awayContactEmailValidator
    ]);
  }

  private emailMatchValidator = (fg: FormGroup) =>
    fg.value.email === fg.value.emailMatch ? null : { mismatch: true };

  /**
   * @method: awayContactMobileValidator
   * @returns: { any } an error object if form is invalid else return null
   * @description: Check if Away Service (Wandering) Contacts in the form contain at least one mobile number.
   */
  private awayContactMobileValidator = (fg: FormGroup) => {
    const isAwayRoleSelected = fg.value.role &&
      fg.value.role.includes(this.vaisFormsService.contactTypes.awayServiceContact);
    const hasCell = fg.value.phoneDetails.some(field => field.carePhoneType === 'Cell');
    if (isAwayRoleSelected && !hasCell) {
      return { mobileRequiredForAwayContact: true };
    } else {
      return null;
    }
  };

  /**
   * @method: awayContactEmailValidator
   * @returns: { any } an error object if form is invalid else return null
   * @description: Check if Away Service (Wandering) Contacts in the form contain an email address.
   */
  private awayContactEmailValidator = (fg: FormGroup) => {
    const isAwayRoleSelected = fg.value.role &&
      fg.value.role.includes(this.vaisFormsService.contactTypes.awayServiceContact);
    const hasEmail = !!fg.value.email;
    if (isAwayRoleSelected && !hasEmail) {
      return { emailRequired: true };
    } else {
      return null;
    }
  };

  private initPhoneDetails() {
    return new FormGroup({
      carePhone: new FormControl('', [Validators.required, Validators.pattern(/^\d{3}-\d{3}-\d{4}$/)]),
      carePhoneType: new FormControl('', Validators.required)
    });
  }

  /**
   * @returns: true if form Away Service (Wandering) Contact role selection is valid
   * @description: Validates if form contains Away Service (Wandering) Contact if required
   */
  private hasAwayContactIfRequired(): boolean {
    const detailsForm = this.emergencyContactInfoForm.controls.details;
    const hasAwayContact = detailsForm.value.some(field => field.role.includes(this.vaisFormsService.contactTypes.awayServiceContact));
    if (this.isAwayContactRequired$.value && !hasAwayContact) {
      setTimeout(() => {
        detailsForm.setErrors({ awayContactRequired: true });
      }, 0);
      return false;
    } else {
      const error = 'awayContactRequired';
      if (detailsForm.hasError(error)) {
        delete detailsForm.errors[error];
      }
      return true;
    }
  }

  private emergencyContactInfoFormIfUntouched() {
    const control = this.emergencyContactInfoForm.controls.details as FormArray;
    if (!control.touched && control.length > 0) {
      control.clear();
    }
  }

  /**
   * Removes emergency contact forms which only have an Away Service (Wandering) Contact Role.
   * Else removes Away Service (Wandering) Contact role from roles if emergency contact has multiple roles.
   */
  private deleteAwayContacts() {
    const emergencyContactInfoForms = this.emergencyContactInfoForm.controls.details as FormArray;
    for (let i = emergencyContactInfoForms.value.length - 1; i >= 0; i--) {
      const currentRoles = emergencyContactInfoForms.value[i].role;
      if (currentRoles instanceof Array) {
        const form = emergencyContactInfoForms.controls[i] as FormGroup;
        const nonAwayContactRoles = currentRoles.filter(role => role !== this.vaisFormsService.contactTypes.awayServiceContact);
        if (nonAwayContactRoles.length > 0) {
          form.controls.role.setValue(nonAwayContactRoles);
        } else {
          emergencyContactInfoForms.removeAt(i);
        }
      }
    }
    this.vaisFormsService.emergencyContactInfoForm$.next(this.emergencyContactInfoForm);
  }
}
