import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppConfig } from 'src/app/shared/services/app-config.service';
import { AuthenticatePayload } from 'src/app/config-manager/model/authenticate-payload.model';
import { LoggedInUserData } from '../models/logged-in-user-data.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedInSubject$: BehaviorSubject<boolean>;

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private router: Router
  ) {
    if (this.isLoginDataStored()) {
      this.isLoggedInSubject$ = new BehaviorSubject<boolean>(true);
    } else {
      this.isLoggedInSubject$ = new BehaviorSubject<boolean>(false);
    }
  }

  authenticate$(credential: AuthenticatePayload): Observable<any> {
    const authenticateApiOptions = this.enrollmentApiOptions();
    return this.httpClient.post(this.appConfig.config.enrollmentApiOktaAuth, credential, authenticateApiOptions);
  }

  updateLoggedInStatus(isLoggedIn: boolean) {
    if (isLoggedIn === false) {
      localStorage.removeItem(this.appConfig.config.localStorageRepUser);
    }
    this.isLoggedInSubject$.next(isLoggedIn);
  }

  logout() {
    this.updateLoggedInStatus(false);
    this.httpClient.get(this.appConfig.config.enrollmentApiOktaLogout).subscribe(
      () => {}, ()=> {}, () => this.redirectToLogin()
    );
  }

  redirectToLogin() {
    this.router.navigateByUrl(`/configmanager-login`);
  }

  isLoggedIn$(): Observable<boolean> {
    return this.isLoggedInSubject$.asObservable();
  }

  private isLoginDataStored(): boolean {
    const repDataString = localStorage.getItem(this.appConfig.config.localStorageRepUser);
    if (repDataString) {
      const repData: LoggedInUserData = JSON.parse(repDataString);
      if (repData && !!repData.userName) {
        return true;
      }
    }
    return false;
  }

  private enrollmentApiOptions() {
    return {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('appId', this.appConfig.config.enrollmentAppId)
        .set('appSecret', this.appConfig.config.enrollmentAppSecret)
    };
  }
}
