import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import {
  BrowserPageComponent, FeedbackComponent, ModalComponent,
  SelectProviderComponent, ToolTipComponent
} from './components';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { FormUppercaseDirective, PhoneFormatDirective, NumericFormatDirective, ZipFormatDirective } from './directives';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PreventCopyDirective } from './directives/prevent-copy.directive';
import { MaterialModule } from '../material.module';
import { MockInputElementPreventCopyComponent } from './mocks/mock-input-element-prevent-copy/mock-input-element-prevent-copy.component';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';

@NgModule({
  declarations: [FormUppercaseDirective, FeedbackComponent, PhoneFormatDirective, PhoneNumberComponent,
    BrowserPageComponent, ModalComponent, ClickOutsideDirective, ToolTipComponent, NavigationMenuComponent,
    PreventCopyDirective, SelectProviderComponent, ConfirmModalComponent, MockInputElementPreventCopyComponent,
    PhoneNumberComponent, ZipFormatDirective, NumericFormatDirective
  ],
  imports: [
    CommonModule, MaterialModule, NgbModule, ReactiveFormsModule, MatSelectModule, NgxMatSelectSearchModule
  ],
  exports: [FormUppercaseDirective, FeedbackComponent, PhoneFormatDirective, PhoneNumberComponent, NavigationMenuComponent,
    BrowserPageComponent, ModalComponent, ToolTipComponent, PreventCopyDirective, SelectProviderComponent, ConfirmModalComponent,
    ZipFormatDirective, NumericFormatDirective
  ],
  entryComponents: [ModalComponent, ToolTipComponent, ConfirmModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class SharedModule { }
