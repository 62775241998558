import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import { VaisFormModalComponent } from '../vais-form-modal/vais-form-modal.component';
import { Account, Address, Email } from 'src/app/enrollment-forms/models/account.model';
import { VaisFormsService } from '../../services';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { HttpErrorService } from 'src/app/error-handler/http-error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Phone } from 'src/app/enrollment-forms/models/phone';
import { PRODUCT_DEFAULTS, Product } from 'src/app/shared/models/product.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { VaisModalForms } from 'src/app/shared/models/vais-modal-forms.enum';

@Component({
  selector: 'app-vais-review-order',
  templateUrl: './vais-review-order.component.html',
  styleUrls: ['./vais-review-order.component.css']
})
export class VaisReviewOrderComponent extends FormCanDeactivate implements OnInit {
  @Output() nextEvent = new EventEmitter<void>();
  @Output() previousEvent = new EventEmitter<void>();

  @ViewChild('formFive', { static: false }) form: NgForm;
  @ViewChild('progressBar', { static: false }) progressBar!: ProgressBarComponent;

  reviewForm: FormGroup;
  acceptTermsCheckbox = false;

  showMemberInformation: boolean;
  showEmergencyContactInfo: boolean;
  showProductSelection: boolean;

  isAwayServiceDisabled = false;
  isEmergencyContactFormValid = true;
  isFootnoteVisible = false;

  isSubmitting = false;

  errorCreatingAccount = false;
  errorProcessingPayment = false;
  paymentError = false;
  paymentErrorCode: string = null;

  memberCardNo: string;
  displayName: string;

  emergencyContactInfoFormValues: any;
  memberInformationFormValues: { [key: string]: any };

  product: Product = { ...PRODUCT_DEFAULTS };

  didUserAgreeToAwayServiceTerms = false;
  primaryPhoneType: string;

  vaisModalForms = VaisModalForms;

  stepTitle: string;

  constructor(
    private reviewFormBuilder: FormBuilder,
    private formModalService: NgbModal,
    private vaisFormsService: VaisFormsService,
    private router: Router,
    private route: ActivatedRoute,
    private httpErrorService: HttpErrorService,
    private appConfig: AppConfig,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.stepTitle = this.vaisFormsService.steps.vaisReviewOrder;
  }

  ngOnInit() {
    this.reviewForm = this.reviewFormBuilder.group({
      acceptTermsCheckbox: ['', Validators.required]
    });

    this.vaisFormsService.couponVerificationForm$.subscribe(memberVerificationForm => {
      if (memberVerificationForm) {
        this.memberCardNo = memberVerificationForm.value.couponCode;
      }
    });

    this.vaisFormsService.memberInformationForm$.subscribe((memberInformationForm: FormGroup) => {
      if (memberInformationForm) {
        const rawValues = memberInformationForm.getRawValue();
        this.memberInformationFormValues = Object.keys(rawValues).reduce((updatedValues, key) => {
          const value = rawValues[key];
          updatedValues[key] = typeof value === 'string' ? value.toUpperCase() : value;
          return updatedValues;
        }, {} as { [key: string]: any });

        const firstName = this.memberInformationFormValues.firstName;
        const middleName = this.memberInformationFormValues.middleName;
        const lastName = this.memberInformationFormValues.lastName;
        if (firstName && lastName) {
          this.displayName = `${firstName} ${middleName} ${lastName}`.replace(/  /g, ' ');
        }
      }
    });

    this.vaisFormsService.emergencyContactInfoForm$.subscribe(emergencyContactInfoForm => {
      if (emergencyContactInfoForm && emergencyContactInfoForm.controls && Object.keys(emergencyContactInfoForm.controls).length > 0) {
        const emergencyContactInfoForms = emergencyContactInfoForm.controls.details as FormArray;

        emergencyContactInfoForms.controls.forEach((individualEmergencyContact: FormGroup) => {
          this.upperCaseFormControl(individualEmergencyContact.controls.firstName);
          this.upperCaseFormControl(individualEmergencyContact.controls.middleName);
          this.upperCaseFormControl(individualEmergencyContact.controls.lastName);
          this.upperCaseFormControl(individualEmergencyContact.controls.email);
          this.upperCaseFormControl(individualEmergencyContact.controls.emailMatch);
        });

        this.emergencyContactInfoFormValues = emergencyContactInfoForms.value;
        this.isEmergencyContactFormValid = emergencyContactInfoForms.valid;
        this.cdr.detectChanges();
      }
    });

    this.vaisFormsService.productSelectionForm$.subscribe(productSelectionForm => {
      if (productSelectionForm && productSelectionForm.controls && Object.keys(productSelectionForm.controls).length > 0) {
        this.didUserAgreeToAwayServiceTerms = productSelectionForm.value.didUserAgreeToAwayServiceTerms;

        if (productSelectionForm.value.phoneType === this.vaisFormsService.phoneTypes.landline) {
          this.primaryPhoneType = 'Home';
        } else {
          this.primaryPhoneType = 'Cell';
        }
      }
    });

    this.getProductInformation();
  }

  toggleFootnote(state: boolean = null) {
    if (state === null) {
      this.isFootnoteVisible = !this.isFootnoteVisible;
    } else {
      this.isFootnoteVisible = state;
    }
  }

  showForms(form: VaisModalForms) {
    this.vaisFormsService.showAsModal$.next(true);
    const formModal = this.formModalService.open(VaisFormModalComponent, {
      windowClass: 'modal',
      size: 'lg',
      centered: true,
      backdrop: 'static'
    });
    formModal.componentInstance.formType = form;
    formModal.result.catch((reason) => {
      if (reason && reason.newForm) {
        this.showForms(reason.newForm);
      }
    });
  }

  submitOrder() {
    this.isSubmitting = true;
    this.cdr.detectChanges(); // Detect progressBar

    const accounts: Array<Account> = [];
    const memberAddresses: Address[] = [];
    const memberPhones: Phone[] = [];
    const memberEmails: Email[] = [];

    memberAddresses.push(this.formatServiceAddress(this.memberInformationFormValues));
    memberPhones.push({ phoneNumber: this.memberInformationFormValues.phoneNumber, type: this.primaryPhoneType });
    memberEmails.push({ address: this.memberInformationFormValues.email, type: 'Personal' });

    accounts.push(this.accountData(true,
      this.memberInformationFormValues,
      memberPhones,
      memberEmails,
      memberAddresses,
      this.memberInformationFormValues.dob,
      this.memberInformationFormValues.gender
    ));

    accounts.push({
      firstName: this.memberInformationFormValues.shippingFirstName || this.memberInformationFormValues.firstName,
      lastName: this.memberInformationFormValues.shippingLastName || this.memberInformationFormValues.lastName,
      addresses: [this.formatShippingAddress(this.memberInformationFormValues)],
      phones: [
        {
          phoneNumber: this.memberInformationFormValues.shippingPhoneNumber || this.memberInformationFormValues.phoneNumber,
          type: 'Cell'
        }
      ],
      isCareRecipient: true,
      isShippingInformation: true
    });

    this.emergencyContactInfoFormValues.forEach(emergencyContactControls => {
      accounts.push(this.accountData(false,
        emergencyContactControls,
        emergencyContactControls.phoneDetails.map((phone: any) => ({
          phoneNumber: phone.carePhone,
          type: phone.carePhoneType
        })),
        [{
          address: emergencyContactControls.email,
          type: 'Home'
        }],
        emergencyContactControls.address,
        '',
        ''
      ));
    });

    this.vaisFormsService.createAccountWithPayment$(accounts, this.progressBar).subscribe(() => {
      this.vaisFormsService.clearForms();
      this.isSubmitting = false;
      this.errorCreatingAccount = false;
      this.errorProcessingPayment = false;

      const providerRoute = this.router.url;
      this.router.navigateByUrl(`${providerRoute}/success`);
    }, (error: unknown) => {
      this.vaisFormsService.accountCreationInProgress = false;
      this.vaisFormsService.paymentProcessingInProgress = false;
      this.isSubmitting = false;

      if (this.appConfig.config.debug) {
        console.log('Error submitting payment form');
        console.log(error);
      }

      if (!(error instanceof HttpErrorResponse)) {
        this.errorCreatingAccount = true;
        return;
      }

      this.httpErrorService.logHttpErrorDetailsIfDevelopment(error);

      this.paymentErrorCode = error.toString();

      if (error.url !== this.appConfig.config.enrollmentApiPayment) {
        this.errorCreatingAccount = true;
        return;
      }

      this.errorProcessingPayment = true;

      if (error.error.status) {
        this.paymentErrorCode = error.error.status;
      } else {
        this.paymentErrorCode = 'Unknown error';
      }
    });
  }

  hideError() {
    this.isSubmitting = false;
    this.errorCreatingAccount = false;
    this.errorProcessingPayment = false;
  }

  exitPage() {
    this.vaisFormsService.clearForms();
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private getJobNos(product: Product, productSelectionForm: FormGroup, environment: string): string[] {
    const isFallDetectionRequested = productSelectionForm.value.isFallDetectionRequested;
    const isHomeInstallationRequested = productSelectionForm.value.isHomeInstallationRequested;
    const jobNosAllEnvironments = product.jobNoOptions.filter(jobNoOption =>
      (isHomeInstallationRequested ? jobNoOption.withInstallation === true : !jobNoOption.withInstallation) &&
      (isFallDetectionRequested ? jobNoOption.withFallDetection === true : !jobNoOption.withFallDetection));
    const jobNoInformation = jobNosAllEnvironments.find(jobNoOption => jobNoOption.environment === environment) ||
      jobNosAllEnvironments.find(jobNoOption => !jobNoOption.environment);

    return [jobNoInformation ? jobNoInformation.jobNo : null];
  }

  private getProductInformation() {
    this.vaisFormsService.productInfo$.subscribe(product => {
      if (product) {
        this.product = product;
      }
    });
  }

  private accountData(
    isCareRecipient: boolean,
    formData: any,
    phoneData: Array<Phone>,
    emails?: Array<Email>,
    addressData?: Array<Address>,
    dob?: string,
    gender?: string
  ): Account {
    return {
      requiresTTY: false,
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      language: formData.language,
      gender,
      phones: phoneData,
      addresses: isCareRecipient ? addressData : [],
      emails: emails ? emails.map(email => ({
        address: email.address.toUpperCase(),
        type: email.type
      })) : null,
      dateOfBirth: isCareRecipient ? moment(dob).format('YYYY-MM-DD') : null,
      isCareRecipient,
      locationOfHouseKey: formData.houseKeyExists ? formData.locationOfHouseKey : '',
      jobNumbers: isCareRecipient ? this.getJobNos(
        this.vaisFormsService.productInfo$.getValue(),
        this.vaisFormsService.productSelectionForm$.getValue(),
        this.appConfig.config.environmentName
      ) : null,
      relationship: isCareRecipient ? null : formData.relationship,
      isResponder: isCareRecipient ? null : formData.role.includes(this.vaisFormsService.contactTypes.responder),
      isAwayContact: isCareRecipient ? null : formData.role.includes(this.vaisFormsService.contactTypes.awayServiceContact),
      isNotifier: isCareRecipient ? null : formData.role.includes(this.vaisFormsService.contactTypes.notify)
    };
  }

  private formatServiceAddress(address: any): Address {
    return this.formatAddress(
      address.address1,
      address.address2,
      address.city,
      address.zipCode,
      'Home',
      address.state);
  }

  private formatShippingAddress(address: any): Address {
    return this.memberInformationFormValues.shippingAddress1 ?
      this.formatAddress(
        address.shippingAddress1,
        address.shippingAddress2,
        address.shippingCity,
        address.shippingZipCode,
        'Shipping',
        address.shippingState) :
      this.formatAddress(
        address.address1,
        address.address2,
        address.city,
        address.zipCode,
        'Shipping',
        address.state);
  }

  private formatAddress(line1: string, line2: string, city: string,
    zip: string, addressType: string, state: string): Address {
    return {
      line1,
      line2,
      city,
      zip,
      country: 'USA',
      addressType,
      state,
      status: 'Active',
      isAddressValidated: true
    };
  }

  private upperCaseFormControl(control: AbstractControl) {
    if (control && control.value) {
      control.setValue(control.value.toUpperCase());
    }
  }
}
