import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { VaisFormsService } from '../../services';

@Component({
  selector: 'app-vais-stepper-control',
  templateUrl: './vais-stepper-control.component.html',
  styleUrls: ['./vais-stepper-control.component.css']
})
export class VaisStepperControlComponent implements OnInit {
  @ViewChild(MatStepper, { static: false }) stepper: MatStepper;
  steps: any;

  constructor(private vaisFormsService: VaisFormsService) {
    this.steps = this.vaisFormsService.steps;
  }

  ngOnInit(): void {
  }
}
