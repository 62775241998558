import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { FormsService } from 'src/app/enrollment-forms/services';
import { FormCanDeactivate } from 'src/app/authguard/form-can-deactivate';
import { ConversionFormService } from '../../services/conversion-form.service';
import { yearRangeValidator } from 'src/app/validators/year.validator';
import { monthValidator } from 'src/app/validators/month.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { PaymentInformation } from '../../models/payment-information';
import { AuthService } from 'src/app/shared/services/auth.service';
import { creditCardValidator } from 'src/app/validators/credit-card.validator';
import { ProductPrice } from 'src/app/shared/models/product-price.model';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';

import productprices from '../../../../assets/json/productprices.json';
import { PaymentLineItem } from 'src/app/shared/models/payment-line-item.model';
import { yearAndMonthIsFutureValidator } from 'src/app/validators/year-and-month-is-future.validator';
import { configDateValidator } from 'src/app/validators/config-date.validator';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import * as moment from 'moment';
import { ConversionStepperControlComponent } from '../conversion-stepper-control/conversion-stepper-control.component';
import { SmartyStreetsResponse } from 'src/app/shared/models/smarty-streets-response.model';

@Component({
  selector: 'app-conversion-payment',
  templateUrl: './conversion-payment.component.html',
  styleUrls: ['./conversion-payment.component.css']
})
export class ConversionPaymentComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  @Output() nextEvent = new EventEmitter<number>();
  @Output() previousEvent = new EventEmitter<number>();
  @ViewChild('formThree', { static: false }) form: NgForm;

  isLoggedIn = false;
  conversionPaymentForm: FormGroup;
  defaultPayerLastName: string;
  userProducts: ProductPrice[];
  productPrices: ProductPrice[];
  paymentLineItems: PaymentLineItem[] = [];
  calculatedPrice: number = null;
  showSubmitButtonSpinner = false;
  paymentFailed = false;
  paymentError: string = null;
  isFootnoteVisible = false;
  minimumCardExpirationForDisplay: string;

  errorUnknown = 'Unknown error';

  unknownAddress = false;

  suggestedAddress: string = null;
  suggestedCity: string = null;
  suggestedState: string = null;
  suggestedZipCode: string = null;

  badAddress: string = null;
  badCity: string = null;
  badState: string = null;
  badZipCode: string = null;

  declined = false;
  declineConfirmed = false;
  disableAddressVerification = true;

  providerPhone: string;
  providerExt: string;

  private routingNumberValidators = [Validators.required, Validators.pattern(/^\d{9}$/)];
  private accountNumberValidators = [Validators.required, Validators.pattern(/^\d{8,17}$/)];
  private phoneNumberValidators = [
    Validators.required,
    Validators.pattern(/^\d{10}$/),
    Validators.minLength(10),
    Validators.maxLength(10)
  ];

  private cardholderNameValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.maxLength(40)
  ];
  private streetValidators = [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(30),
    this.validStreet.bind(this)
  ];
  private address2Validators = [
    Validators.maxLength(30)
  ];
  private cityValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.minLength(3),
    Validators.maxLength(40),
    this.validCity.bind(this)
  ];
  private stateValidators = [
    Validators.required,
    Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
    Validators.minLength(2),
    Validators.maxLength(2),
    this.validState.bind(this)
  ];
  private zipCodeValidators = [
    Validators.required,
    Validators.pattern(/^[0-9]{5}(-[0-9]{4})?$/),
    Validators.minLength(5),
    this.validZipCode.bind(this)
  ];
  private cardNumberValidators = [
    Validators.required,
    Validators.pattern(/^\d{13,19}$/),
    creditCardValidator()
  ];
  private expirationDateMonthValidators = [
    Validators.required,
    Validators.pattern(/^\d{2}$/),
    monthValidator()
  ];
  private expirationDateYearValidators = [
    Validators.required,
    Validators.pattern(/^\d{4}$/),
    yearRangeValidator(new Date().getFullYear(), new Date().getFullYear() + 10)
  ];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private conversionFormsService: ConversionFormService,
    private conversionPaymentFormBuilder: FormBuilder,
    private formsService: FormsService,
    private authService: AuthService,
    private appConfig: AppConfig,
    private conversionStepperControlComponent: ConversionStepperControlComponent
  ) {
    super();

    if (this.appConfig.config.conversionMinCreditCardExpiration) {
      const minimumExpiration = this.appConfig.config.conversionMinCreditCardExpiration;
      this.minimumCardExpirationForDisplay = moment([minimumExpiration.getFullYear(), minimumExpiration.getMonth()]).format('YYYY-MM');
    }
  }

  ngOnInit() {
    this.productPrices = productprices;

    this.authService.isLoggedIn$().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe((careDetails: Provider) => {
      if (careDetails) {
        this.providerPhone = careDetails.phoneno;
        this.providerExt = careDetails.ext;
      }
    });

    this.conversionFormsService.productInformation$.subscribe(userProduct => {
      this.userProducts = this.productPrices.filter(product =>
        product.systemType === userProduct.systemType &&
        product.systemFamily === userProduct.systemFamily
      ).map(product => ({
        ...product,
        title: (product.title || 'Lifeline Alert System') + (userProduct.hasFallDetection ? ' with Fall Detection' : ''),
        image: product.image || '/ssep/assets/images/on-the-go.png'
      }));

      this.paymentLineItems = [];

      if (this.userProducts.length === 1 && this.userProducts[0].price) {
        this.paymentLineItems.push({
          billCode: this.userProducts[0].billCode,
          description: this.userProducts[0].title || this.userProducts[0].systemType,
          amount: this.userProducts[0].price
        });

        if (userProduct.hasFallDetection && this.userProducts[0].fallDetectionPrice) {
          this.paymentLineItems.push({
            billCode: this.userProducts[0].fallDetectionBillCode,
            description: 'Fall detection',
            amount: this.userProducts[0].fallDetectionPrice
          });

          if (userProduct.hasMultipleSubscribers) {
            this.paymentLineItems.push({
              billCode: this.userProducts[0].additionalFallDetectionBillCode,
              description: 'Additional subscriber fall detection',
              amount: this.userProducts[0].fallDetectionPrice
            });
          }
        }

        if (userProduct.hasMultipleSubscribers) {
          if (userProduct.hasMultipleMobileDevices) {
            this.paymentLineItems.push({
              billCode: this.userProducts[0].additionalSubscriberBillCode,
              description: 'Additional subscriber monitoring',
              amount: this.userProducts[0].price
            });
          } else if (this.userProducts[0].additionalSubscriberPrice) {
            this.paymentLineItems.push({
              billCode: this.userProducts[0].additionalSubscriberBillCode,
              description: 'Additional subscriber monitoring',
              amount: this.userProducts[0].additionalSubscriberPrice
            });
          }
        }

        this.calculatedPrice = this.paymentLineItems.reduce((total, item) => total += item.amount, 0);
      }
    });

    this.conversionPaymentForm = this.conversionPaymentFormBuilder.group({
      chargesAccepted: [false, Validators.requiredTrue],
      isAccountHolder: [null, Validators.required],
      payerFirstName: ['',
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.maxLength(40)
        ]
      ],
      payerLastName: ['',
        [
          Validators.required,
          Validators.pattern(/^([A-Za-z\-\']+ )+[A-Za-z\-\']+$|^[A-Za-z\-\']+$/),
          Validators.maxLength(40)
        ]
      ],
      phoneNumber: ['', this.phoneNumberValidators],
      isPayingByACH: [null, Validators.required],
      routingNumber: ['', null],
      accountNumber: ['', null],
      cardholderName: ['', this.cardholderNameValidators],
      street: ['', this.streetValidators],
      address2: ['', this.address2Validators],
      city: ['', this.cityValidators],
      state: ['', this.stateValidators],
      zipCode: ['', this.zipCodeValidators],
      cardNumber: ['', this.cardNumberValidators],
      expirationDateMonth: ['', this.expirationDateMonthValidators],
      expirationDateYear: ['', this.expirationDateYearValidators],
      addressOverride: [false]
    }, {
      validators: [
        yearAndMonthIsFutureValidator('expirationDateMonth', 'expirationDateYear'),
        configDateValidator('expirationDateMonth', 'expirationDateYear', this.appConfig.config.conversionMinCreditCardExpiration)
      ]
    });

    this.setValidatorsByPaymentMethod(this.conversionPaymentForm.controls.isPayingByACH.value);

    this.checkSelection();

    this.conversionPaymentForm.controls.street.valueChanges.subscribe(() => {
      this.checkIfBadAddressChanged();
      if (this.suggestedAddress) {
        if (this.conversionPaymentForm.controls.street.value.toLowerCase() === this.suggestedAddress.toLowerCase()) {
          this.suggestedAddress = null;
          this.updateAllPaymentValidators(this.conversionPaymentForm.controls);
        }
      }
    });

    this.conversionPaymentForm.controls.city.valueChanges.subscribe(() => {
      this.checkIfBadAddressChanged();
      if (this.suggestedCity) {
        if (this.conversionPaymentForm.controls.city.value.toLowerCase() === this.suggestedCity.toLowerCase()) {
          this.suggestedCity = null;
          this.updateAllPaymentValidators(this.conversionPaymentForm.controls);
        }
      }
    });

    this.conversionPaymentForm.controls.state.valueChanges.subscribe(() => {
      this.checkIfBadAddressChanged();
      if (this.suggestedState) {
        if (this.conversionPaymentForm.controls.state.value.toLowerCase() === this.suggestedState.toLowerCase()) {
          this.suggestedState = null;
          this.updateAllPaymentValidators(this.conversionPaymentForm.controls);
        }
      }
    });

    this.conversionPaymentForm.controls.zipCode.valueChanges.subscribe(() => {
      this.checkIfBadAddressChanged();
      if (this.suggestedZipCode) {
        if (this.conversionPaymentForm.controls.zipCode.value === this.suggestedZipCode) {
          this.suggestedZipCode = null;
          this.updateAllPaymentValidators(this.conversionPaymentForm.controls);
        }
      }
    });

    this.conversionPaymentForm.controls.addressOverride.valueChanges.subscribe(() => {
      if (this.conversionPaymentForm.controls.addressOverride.value) {
        this.suggestedAddress = null;
        this.suggestedCity = null;
        this.suggestedState = null;
        this.suggestedZipCode = null;
      };
      this.updateAllPaymentValidators(this.conversionPaymentForm.controls);
    });
  }

  checkIfBadAddressChanged() {
    if (this.unknownAddress) {
      if (this.conversionPaymentForm.controls.street.value !== this.badAddress ||
        this.conversionPaymentForm.controls.city.value !== this.badCity ||
        this.conversionPaymentForm.controls.state.value !== this.badState ||
        this.conversionPaymentForm.controls.zipCode.value !== this.badZipCode
      ) {
        this.unknownAddress = false;
        this.suggestedAddress = null;
        this.updateAllPaymentValidators(this.conversionPaymentForm.controls);
      }
    }
  }

  checkSelection() {
    const payerLastName = this.conversionPaymentForm.controls.payerLastName;

    this.conversionPaymentForm.controls.isAccountHolder.valueChanges
      .subscribe(isAccountHolder => {
        if (isAccountHolder) {
          if (this.conversionFormsService.formConversion &&
            this.conversionFormsService.formConversion.controls &&
            Object.keys(this.conversionFormsService.formConversion.controls).length > 0 &&
            this.conversionFormsService.formConversion.controls.lastName.value) {
            payerLastName.setValue(this.conversionFormsService.formConversion.controls.lastName.value);
            payerLastName.disable();
          }
        } else {
          payerLastName.enable();
        }
      });

    this.conversionPaymentForm.controls.isPayingByACH.valueChanges
      .subscribe(isPayingByACH => {
        this.setValidatorsByPaymentMethod(isPayingByACH);
      });
  }

  setValidatorsByPaymentMethod(isPayingByACH: boolean) {
    const controls = this.conversionPaymentForm.controls;

    if (isPayingByACH) {
      this.setValidatorsForAch(controls);
    } else {
      this.setValidatorsForCreditCard(controls);
    }

    this.updateAllPaymentValidators(controls);
  }

  enableFields() {
    this.formsService.careDetails$.pipe(takeUntil(this.destroy$)).subscribe((careDetails: { name: string }) => {
      const memberCareName = careDetails.name.toLowerCase();
      if (memberCareName === 'uhc') {
        this.conversionPaymentForm.controls.firstName.enable();
        this.conversionPaymentForm.controls.firstName.setValue('');
        this.conversionPaymentForm.controls.lastName.enable();
        this.conversionPaymentForm.controls.lastName.setValue('');
      }
    });
  }

  submitPaymentForm() {
    this.showSubmitButtonSpinner = true;

    this.conversionFormsService.formPayment = this.conversionPaymentForm;

    const isPayingByACH = this.conversionPaymentForm.controls.isPayingByACH.value;

    const paymentInformation: PaymentInformation = {
      firstName: this.conversionPaymentForm.controls.payerFirstName.value,
      lastName: this.conversionPaymentForm.controls.payerLastName.value,
      streetAddress: this.conversionPaymentForm.controls.street.value,
      address2: this.conversionPaymentForm.controls.address2.value,
      city: this.conversionPaymentForm.controls.city.value,
      state: this.conversionPaymentForm.controls.state.value,
      zipcode: this.conversionPaymentForm.controls.zipCode.value,
      accountNumber: isPayingByACH ?
        this.conversionPaymentForm.controls.accountNumber.value :
        this.conversionPaymentForm.controls.cardNumber.value,
      routingNumber: isPayingByACH ? this.conversionPaymentForm.controls.routingNumber.value : null,
      endYear: isPayingByACH ? null : this.conversionPaymentForm.controls.expirationDateYear.value,
      endMonth: isPayingByACH ? null : this.conversionPaymentForm.controls.expirationDateMonth.value,
      holderName: isPayingByACH ? null : this.conversionPaymentForm.controls.cardholderName.value,
      cardType: isPayingByACH ? 'ACH' : 'CreditCard',
      price: this.calculatedPrice,
      paymentLineItems: this.paymentLineItems,
      phoneNumber: this.conversionPaymentForm.controls.phoneNumber.value
    };

    this.verifyAddress$(paymentInformation).pipe(
      switchMap((addressResult: boolean) => {
        this.updateAllPaymentValidators(this.conversionPaymentForm.controls);

        if (!addressResult) {
          this.showSubmitButtonSpinner = false;
          return EMPTY;
        }

        return this.conversionFormsService.submitPayment$(paymentInformation);
      })
    ).subscribe(() => {
      this.showSubmitButtonSpinner = false;
      this.nextEvent.emit();
    }, (error: unknown) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 500) {
          const errorResponse = error.error;
          if (errorResponse && errorResponse.status) {
            this.paymentFailure(`${errorResponse.status}`);
          } else {
            this.paymentFailure('Internal Server Error (500)');
          }
        } else {
          this.paymentFailure(this.errorUnknown);
        }
      }
    });
  }

  paymentFailure(error: string) {
    this.paymentFailed = true;
    this.paymentError = error;
    this.showSubmitButtonSpinner = false;
  }

  hideError() {
    this.paymentFailed = false;
  }

  decline() {
    this.declined = true;
  }

  confirmDecline() {
    this.conversionFormsService.refusePayment$().subscribe(() => {});
    this.declineConfirmed = true;
  }

  reverseDecline() {
    this.declined = false;
  }

  startOver() {
    this.declined = false;
    this.declineConfirmed = false;
    this.conversionStepperControlComponent.startOver();
  }

  exit() {
    window.location.reload();
  }

  conversionPaymentFormPatch(memberValues: FormGroup) {
    if (!memberValues || !memberValues.controls) {
      return;
    }

    this.conversionPaymentForm.patchValue({
      isAccountHolder: memberValues.controls.isAccountHolder.value,
      payerFirstName: memberValues.controls.payerFirstName.value,
      payerLastName: memberValues.controls.payerLastName.value,

      isPayingByACH: memberValues.controls.isPayingByACH.value,

      routingNumber: memberValues.controls.routingNumber.value,
      accountNumber: memberValues.controls.accountNumber.value,

      cardholderName: memberValues.controls.cardholderName.value,
      street: memberValues.controls.street.value,
      address2: memberValues.controls.address2.value,
      city: memberValues.controls.city.value,
      state: memberValues.controls.state.value,
      cardNumber: memberValues.controls.cardNumber.value,
      expirationDateMonth: memberValues.controls.expirationDateMonth.value,
      expirationDateYear: memberValues.controls.expirationDateYear.value,
      zipCode: memberValues.controls.zipCode.value,
    });
  }

  toggleFootnote(state: boolean = null) {
    if (state === null) {
      this.isFootnoteVisible = !this.isFootnoteVisible;
    } else {
      this.isFootnoteVisible = state;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setValidatorsForAch(controls: { [key: string]: AbstractControl }) {
    controls.routingNumber.setValidators(this.routingNumberValidators);
    controls.accountNumber.setValidators(this.accountNumberValidators);
    controls.phoneNumber.setValidators(this.phoneNumberValidators);
    controls.cardholderName.clearValidators();
    controls.street.setValidators(this.streetValidators);
    controls.address2.setValidators(this.address2Validators);
    controls.city.setValidators(this.cityValidators);
    controls.state.setValidators(this.stateValidators);
    controls.zipCode.setValidators(this.zipCodeValidators);
    controls.cardNumber.clearValidators();
    controls.expirationDateYear.clearValidators();
    controls.expirationDateMonth.clearValidators();
  }

  private setValidatorsForCreditCard(controls: { [key: string]: AbstractControl }) {
    controls.routingNumber.clearValidators();
    controls.accountNumber.clearValidators();
    controls.phoneNumber.setValidators(this.phoneNumberValidators);
    controls.cardholderName.setValidators(this.cardholderNameValidators);
    controls.street.setValidators(this.streetValidators);
    controls.address2.setValidators(this.address2Validators);
    controls.city.setValidators(this.cityValidators);
    controls.state.setValidators(this.stateValidators);
    controls.zipCode.setValidators(this.zipCodeValidators);
    controls.cardNumber.setValidators(this.cardNumberValidators);
    controls.expirationDateMonth.setValidators(this.expirationDateMonthValidators);
    controls.expirationDateYear.setValidators(this.expirationDateYearValidators);
  }

  private updateAllPaymentValidators(controls: { [key: string]: AbstractControl }) {
    controls.routingNumber.updateValueAndValidity();
    controls.accountNumber.updateValueAndValidity();
    controls.cardholderName.updateValueAndValidity();
    controls.street.updateValueAndValidity();
    controls.address2.updateValueAndValidity();
    controls.city.updateValueAndValidity();
    controls.state.updateValueAndValidity();
    controls.zipCode.updateValueAndValidity();
    controls.cardNumber.updateValueAndValidity();
    controls.expirationDateMonth.updateValueAndValidity();
    controls.expirationDateYear.updateValueAndValidity();
  }

  private verifyAddress$(paymentInformation: PaymentInformation): Observable<boolean> {
    if (this.disableAddressVerification ||
        this.appConfig.config.validateAddresses === false ||
        this.conversionPaymentForm.controls.addressOverride.value) {
      this.suggestedAddress = null;
      this.suggestedCity = null;
      this.suggestedState = null;
      this.suggestedZipCode = null;
      return of(true);
    }

    return this.formsService.getFullAddressInfo(
      paymentInformation.streetAddress,
      paymentInformation.city,
      paymentInformation.state,
      paymentInformation.zipcode
    ).pipe(
      map((matchingAddresses: SmartyStreetsResponse[]) => {
        if (matchingAddresses.length === 0) {
          this.badAddress = paymentInformation.streetAddress;
          this.badCity = paymentInformation.city;
          this.badState = paymentInformation.state;
          this.badZipCode = paymentInformation.zipcode;
          this.suggestedAddress = 'Unknown address';
          this.unknownAddress = true;
          return false;
        }

        let addressValid = true;
        this.unknownAddress = false;

        const { delivery_line_1: correctedAddress } = matchingAddresses[0];
        const {
          city_name: correctedCity,
          state_abbreviation: correctedState,
          zipcode: correctedZipCode
        } = matchingAddresses[0].components;

        if (paymentInformation.streetAddress.toLowerCase() !== correctedAddress.toLowerCase()) {
          this.badAddress = paymentInformation.streetAddress;
          this.suggestedAddress = correctedAddress;
          addressValid = false;
        } else {
          this.suggestedAddress = null;
        }

        if (paymentInformation.city.toLowerCase() !== correctedCity.toLowerCase()) {
          this.suggestedCity = correctedCity;
          addressValid = false;
        } else {
          this.suggestedCity = null;
        }

        if (paymentInformation.state.toLowerCase() !== correctedState.toLowerCase()) {
          this.suggestedState = correctedState;
          addressValid = false;
        } else {
          this.suggestedState = null;
        }

        if (paymentInformation.zipcode.toLowerCase() !== correctedZipCode.toLowerCase()) {
          this.suggestedZipCode = correctedZipCode;
          addressValid = false;
        } else {
          this.suggestedZipCode = null;
        }

        return addressValid;
      }),
      catchError(() => of(false))
    );
  }

  private validStreet(): ValidationErrors | null {
    return this.suggestedAddress ? { validationerrror: this.suggestedAddress } : null;
  }

  private validCity(): ValidationErrors | null {
    return this.suggestedCity ? { validationerrror: this.suggestedCity } : null;
  }

  private validState(): ValidationErrors | null {
    return this.suggestedState ? { validationerrror: this.suggestedState } : null;
  }

  private validZipCode(): ValidationErrors | null {
    return this.suggestedZipCode ? { validationerrror: this.suggestedZipCode } : null;
  }
}
