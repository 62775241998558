import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExpirationDateService {
  year: string = null;
  month: string = null;

  constructor(year: string, month: string) {
    this.setDate(year, month);
  }

  setDate(year: string, month: string) {
    this.year = year;
    this.month = month;
  }

  // Returns the last day of the month.
  // E.g., Year = 2024 Month = 12 should result in 2024-12-31.
  getExpirationAsDate(): Date {
    if (!this.year || !this.month) {
      return null;
    }

    // Date.UTC normally uses a zero-based month; this.month is not zero-based.
    // Normally you would use Number(this.month - 1) to account for this,
    // but since we want the last day of the month this works to our advantage
    // since we can just subtract a day from the "wrong" month.
    const expirationDate = new Date(Date.UTC(Number(this.year), Number(this.month)));

    // Example: At this point expirationDate is '2030-10-01' if year = 2030 and month = 9 (September)
    // This is one day past what we want ('2030-09-30'), so subtract a day.
    expirationDate.setDate(expirationDate.getDate() - 1);
    return expirationDate;
  }

  getExpirationAsString(): string {
    return this.year && this.month ? `${this.year}-${this.month}` : null;
  }
}
