import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appNumericFormat]'
})
export class NumericFormatDirective {
  private _numericControl: AbstractControl;

  constructor(private elementRef: ElementRef) { }

  @Input()
  set numericControl(control: AbstractControl) {
    this._numericControl = control;
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(value: any) {
    if (value !== null) {
      this.onInputChange(value);
    }
  }

  // Phone number value is masked in the format XXX-XXX-XXXX
  onInputChange(value: string) {
    if (!value) {
      return value;
    }

    let start = this.elementRef.nativeElement.selectionStart;
    const end = this.elementRef.nativeElement.selectionEnd;
    const newVal = value.replace(/\D/g, '');

    if (value !== newVal) {
      this._numericControl.setValue(newVal, { emitEvent: false });
    }

    if ((value.length < newVal.length && start === end) || ((start === 4) || start === 8) && value.length !== newVal.length) {
      if (start > 3) {
        start = start + 1;
      }
      if (start > 7) {
        start = start + 1;
      }
    }
    this.elementRef.nativeElement.setSelectionRange(start, start);
  }
}
