import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';

import { Product, PRODUCT_DEFAULTS } from 'src/app/shared/models/product.model';
import { AppConfig } from 'src/app/shared/services/app-config.service';
import { TraceIdService } from 'src/app/enrollment-forms/services/trace-id.service';
import { Account } from 'src/app/enrollment-forms/models/account.model';
import { VaisTokenInformation } from '../models/vais-token-information.model';
import { PlatformSubscriberPaymentRequest } from 'src/app/conversions/models/platform-subscriber-payment-request';
import { AuthService } from 'src/app/shared/services/auth.service';

import vaisproducts from '../../../assets/json/vaisproducts.json';
import { Provider } from 'src/app/enrollment-forms/models/provider.model';

import providers from '../../../assets/json/providers.json';
import { PlatformLocation } from '@angular/common';
import { PlatformApiService } from 'src/app/enrollment-forms/services/platform-api.service';
import { IsEligibleRequest } from '../models/is-eligible.model';
import { EnrollEmailConfirmationRequest } from 'src/app/shared/models/enroll-email-confirmation-request.model';
import { delay, switchMap } from 'rxjs/operators';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { LoggedInUserData } from 'src/app/shared/models/logged-in-user-data.model';
import { ExpirationDateService } from 'src/app/shared/services/expiration-date.service';

interface LastSubmittedApplicant {
  firstName: string;
  lastName: string;
  email: string;
  siteNumber: string;
  price: number;
}

@Injectable({
  providedIn: 'root'
})
export class VaisFormsService {
  showAsModal$ = new BehaviorSubject<boolean>(false);

  couponVerificationForm$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  memberInformationForm$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  productSelectionForm$ = new BehaviorSubject<FormGroup>(new FormGroup({}));
  emergencyContactInfoForm$ = new BehaviorSubject<FormGroup>(new FormGroup({}));

  productInfo$ = new BehaviorSubject<Product>(null);
  tokenInformation$ = new BehaviorSubject<VaisTokenInformation>(new VaisTokenInformation());

  accountCreationInProgress: boolean;
  paymentProcessingInProgress: boolean;

  serviceTypes = {
    all: 'all',
    inHome: 'inHome',
    gpsMobile: 'gpsMobile'
  };

  phoneTypes = {
    all: 'all',
    landline: 'landline',
    cell: 'cell'
  };

  deviceTypes = {
    landline: 'landline',
    cell: 'cell',
    gps: 'gps',
    watch: 'watch'
  };

  contactTypes = {
    responder: 'Responder',
    awayServiceContact: 'Away Service (Wandering) Contact',
    notify: 'Notify'
  };

  steps = {
    vaisCouponVerification: 'Member Information',
    vaisProductSelection: 'Select Your Product',
    vaisMemberInformation: 'Member Contact Information',
    vaisEmergencyContactInfo: 'Emergency Contact Information',
    vaisReviewOrder: 'Review & Complete'
  };

  lastSubmittedApplicant: LastSubmittedApplicant = {
    firstName: '',
    lastName: '',
    email: '',
    siteNumber: '',
    price: 0
  };

  isLoggedIn: boolean;

  prices: {
    price: number;
    description: string;
    onScreenText: string;
  }[];
  totalPrice: number;
  userProduct: Product;
  siteNumber: string;

  private _provider: Provider;

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
    private traceIdService: TraceIdService,
    private authService: AuthService,
    private platformLocation: PlatformLocation,
    private platformApiService: PlatformApiService
  ) {
    this.initialize();
  }

  get provider(): Provider {
    return this._provider;
  }

  initialize() {
    this.authService.isLoggedIn$().subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    this._provider = providers.providers.find(provider => provider.name &&
      this.platformLocation.pathname.split('/').some(urlSegment => urlSegment.toUpperCase() === provider.name.toUpperCase())
    ) || {
      carename: '',
      cardimage: '',
      phoneno: '',
      convertPhoneNo: '',
      vaisPhoneNo: '',
      ext: '',
      name: '',
      affiliateid: '',
      externalid: '',
      vaisCouponCodes: [],
      vaisImage: '',
    };

    if (this.provider.vaisPhoneNo) {
      this._provider.phoneno = this.provider.vaisPhoneNo;
    }

    // Away service always enabled in VAIS, though this could change later.
    this._provider.disableAwayService = false;

    this._provider.vaisCouponCodes = this._provider.vaisCouponCodes ? this._provider.vaisCouponCodes.filter(
      vaisCouponCodes => vaisCouponCodes.environments.includes(this.appConfig.config.environmentName)
    ) : [];

    this.setDefaultVaues();
  }

  calculatePrice() {
    combineLatest([
      this.productInfo$.asObservable(),
      this.productSelectionForm$.asObservable()
    ]).subscribe(([product, productSelectionForm]) => {
      this.prices = [];

      if (product) {
        this.prices.push({
          price: product.prodMonthlyRate,
          description: 'Monthly rate',
          onScreenText: 'Your monthly payment will be '
        });

        this.userProduct = product;

        if (productSelectionForm.value.isFallDetectionRequested &&
          product.fallDetectionPrice) {
          this.prices.push({
            price: product.fallDetectionPrice,
            description: 'Monthly rate',
            onScreenText: 'Your monthly payment for fall detection will be '
          });
        }

        if (product.initialCost > 0) {
          this.prices.push({
            price: product.initialCost,
            description: 'Monthly rate',
            onScreenText: 'Your one-time payment for the device will be '
          });
        }

        if (productSelectionForm.controls.isHomeInstallationRequested &&
          productSelectionForm.value.isHomeInstallationRequested) {
          this.prices.push({
            price: this.appConfig.config.homeInstallationPrice,
            description: 'Monthly rate',
            onScreenText: 'Your one-time payment for installation will be '
          });
        }

        this.totalPrice = this.prices.reduce((runningTotal, price) => runningTotal += price.price, 0);
      }
    });
  }

  scrollToInvalidControl(formGroup: FormGroup) {
    formGroup.markAllAsTouched();
    formGroup.updateValueAndValidity();

    const invalidElement = document.querySelector('.is-invalid') || document.querySelector('.form-error');

    if (invalidElement) {
      invalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      (invalidElement as HTMLElement).focus();
      return;
    }
  }

  checkIfEligible$(
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    zipcode: string,
    phoneNumber: string
  ): Observable<boolean> {
    const externalLookupParams: IsEligibleRequest = {
      firstName,
      lastName,
      dateOfBirth,
      zipcode,
      phoneNumber
    };

    if (this.appConfig.config.fakePlatformCalls) {
      console.log('Fake eligibility check', externalLookupParams);
      return this.simulateCheckIfExistingHttpCall(lastName);
    }

    const externalLookupOptions = this.enrollmentApiOptions();
    return this.httpClient.post<boolean>(this.appConfig.config.enrollmentApiIsEligible, externalLookupParams, externalLookupOptions);
  }

  getFullAddressInfo(street: string, city: string, state: string, zip: string) {
    const fullAdressParams = {
      candidates: '1',
      street,
      city,
      state,
      zip
    };

    const httpOptions = { params: fullAdressParams };
    return this.httpClient.get(this.appConfig.config.addressApiStreetAddress, httpOptions);
  }

  createAccountWithPayment$(accounts: Account[], progressBar: ProgressBarComponent = null): Observable<any> {
    this.lastSubmittedApplicant = {
      firstName: accounts[0].firstName,
      lastName: accounts[0].lastName,
      email: accounts[0].emails[0].address,
      siteNumber: '',
      price: this.totalPrice
    };

    const couponCode = this.couponVerificationForm$.getValue().value.couponCode;
    const serviceCompanyId = this._provider.vaisCouponCodes.find(codeInformation => codeInformation.code === couponCode).serviceCompany;

    const memberInformationFormValues = this.memberInformationForm$.getValue().getRawValue();

    const expirationDate = new ExpirationDateService(
      memberInformationFormValues.expirationDateYear,
      memberInformationFormValues.expirationDateMonth);

    const paymentInformation: PlatformSubscriberPaymentRequest = {
      convertedBy: this.repUserFormatted(),
      firstName: memberInformationFormValues.payerFirstName || accounts[0].firstName,
      lastName: memberInformationFormValues.payerLastName || accounts[0].lastName,
      address1: memberInformationFormValues.paymentAddress1 || accounts[0].addresses[0].line1,
      address2: memberInformationFormValues.paymentAddress2 || accounts[0].addresses[0].line2 ? accounts[0].addresses[0].line2 : null,
      city: memberInformationFormValues.paymentCity || accounts[0].addresses[0].city,
      state: memberInformationFormValues.paymentState || accounts[0].addresses[0].state,
      zipcode: memberInformationFormValues.paymentZipCode || accounts[0].addresses[0].zip,
      couponCode,
      creditCard: {
        accountNumber: memberInformationFormValues.isPayingByACH ?
          memberInformationFormValues.accountNumber :
          memberInformationFormValues.cardNumber,
        routingNumber: memberInformationFormValues.isPayingByACH ? memberInformationFormValues.routingNumber : null,
        expirationDate: memberInformationFormValues.isPayingByACH ? new Date() : expirationDate.getExpirationAsDate(),
        endDate: expirationDate.getExpirationAsString(),
        holderName: memberInformationFormValues.isPayingByACH ?
          (memberInformationFormValues.payerFirstName ?
            memberInformationFormValues.payerFirstName + ' ' + memberInformationFormValues.payerLastName :
            memberInformationFormValues.firstName + ' ' + memberInformationFormValues.lastName
          ) :
          memberInformationFormValues.cardholderName,
        cardType: memberInformationFormValues.isPayingByACH ? 'ACH' : 'CreditCard'
      },
      phoneNumber: (memberInformationFormValues.paymentPhoneNumber || accounts[0].phones[0].phoneNumber).replaceAll(/\D/g, '')
    };

    if (progressBar) {
      progressBar.nextStep();
    }

    if (this.appConfig.config.vaisSeparatePaymentCall) {
      this.accountCreationInProgress = true;
      return this.platformApiService.createAccountWithoutPayment$(accounts, serviceCompanyId).pipe(
        switchMap((createAccountResponse) => {
          this.siteNumber = createAccountResponse.siteNumber;
          this.lastSubmittedApplicant.siteNumber = this.siteNumber;
          paymentInformation.siteNumber = parseInt(this.siteNumber, 10);

          this.accountCreationInProgress = false;
          this.paymentProcessingInProgress = true;

          const externalLookupOptions = this.enrollmentApiOptions();

          if (progressBar) {
            if (this.siteNumber) {
              progressBar.stepDescriptions[1] = `Processing payment for site ${this.siteNumber}`;
            }
            progressBar.nextStep();
          }

          if (this.appConfig.config.fakePlatformCalls) {
            console.log('Fake payment', paymentInformation);
            return this.simulatePaymentHttpCall(paymentInformation.firstName);
          }

          return this.httpClient.post(
            this.appConfig.config.enrollmentApiPayment,
            paymentInformation,
            externalLookupOptions);
        })
      );
    } else {
      const response$ = this.platformApiService.createAccountWithPayment$(accounts, paymentInformation, serviceCompanyId);
      response$.subscribe(response => {
        this.accountCreationInProgress = false;
        this.paymentProcessingInProgress = false;
        this.siteNumber = response.siteNumber;
        this.lastSubmittedApplicant.siteNumber = this.siteNumber;
      }, () => { }, () => this.paymentProcessingInProgress = false);
      return response$;
    }
  }

  clearForms() {
    this.traceIdService.generateNewId();
    this.setDefaultVaues();
  }

  sendConfirmationEmail() {
    const enrollEmailConfirmationRequest: EnrollEmailConfirmationRequest = {
      bodyType: `${this.lastSubmittedApplicant.firstName} ${this.lastSubmittedApplicant.lastName}`,
      sender: `$${this.lastSubmittedApplicant.price.toFixed(2)}`,
      receiver: this.lastSubmittedApplicant.email,
      siteNumber: this.lastSubmittedApplicant.siteNumber
    };

    const externalLookupOptions = this.enrollmentApiOptions();
    return this.httpClient.post(this.appConfig.config.enrollmentApiMessageEnrollEmailConfirmation,
      enrollEmailConfirmationRequest, externalLookupOptions);
  }


  inHomeDevices(): Product[] {
    return [...this.cellDevices(), ...this.landlineDevices()];
  }

  landlineDevices(): Product[] {
    return vaisproducts.products.filter((product: Product) =>
      product.deviceType.toUpperCase() === this.deviceTypes.landline.toUpperCase());
  }

  cellDevices(): Product[] {
    return vaisproducts.products.filter((product: Product) =>
      product.deviceType.toUpperCase() === this.deviceTypes.cell.toUpperCase());
  }

  // false only shows devices *without* away service; null show *all* GPS devices.
  gpsDevices(showDevicesWithAwayService: boolean = null): Product[] {
    return vaisproducts.products.filter((product: Product) =>
      product.deviceType.toUpperCase() === this.deviceTypes.gps.toUpperCase() &&
      (showDevicesWithAwayService === null || product.hasAwayService === showDevicesWithAwayService));
  }

  allDevices(): Product[] {
    return vaisproducts.products.filter((product: Product) =>
      !this._provider.disableAwayService || product.hasAwayService === false)
      .sort((a: Product, b: Product) => a.deviceType.toUpperCase().localeCompare(b.deviceType.toUpperCase()));
  }

  debugAllValidators(form: FormGroup): string {
    let errors = '';
    Object.keys(form.controls).forEach((controlName) => {
      const control = form.get(controlName);
      if (control.errors !== null) {
        errors += controlName + ': ' + this.stringifyIfDevelopment(control.errors) + '\n===\n';
      }
    });
    return errors;
  }

  stringifyIfDevelopment(object: any) {
    return this.appConfig.config.production ? '' : JSON.stringify(object);
  }

  private setDefaultVaues() {
    this.showAsModal$.next(false);

    this.couponVerificationForm$.next(new FormGroup({}));
    this.memberInformationForm$.next(new FormGroup({}));
    this.productSelectionForm$.next(new FormGroup({}));
    this.emergencyContactInfoForm$.next(new FormGroup({}));

    this.productInfo$.next(null);
    this.tokenInformation$.next(new VaisTokenInformation());
    this.productInfo$ = new BehaviorSubject<Product>(null);
    this.tokenInformation$ = new BehaviorSubject<VaisTokenInformation>(new VaisTokenInformation());

    this.accountCreationInProgress = false;
    this.paymentProcessingInProgress = false;

    this.isLoggedIn = false;

    this.prices = [];
    this.totalPrice = 0;
    this.userProduct = { ...PRODUCT_DEFAULTS };
    this.siteNumber = '';
  }

  private repUserFormatted(): string {
    const repUserJson = localStorage.getItem(this.appConfig.config.localStorageRepUser);

    if (this.isLoggedIn && repUserJson) {
      try {
        const repUser: LoggedInUserData = JSON.parse(repUserJson);
        return `${repUser.userName} (${repUser.firstName} ${repUser.lastName})`;
      } catch {
        return `Unknown (unknown)`;
      }
    } else if (this.isLoggedIn) {
      return `Unknown (unknown)`;
    } else {
      return 'Subscriber (self)';
    }
  }

  private enrollmentApiOptions() {
    return {
      headers: new HttpHeaders()
        .set('AppId', this.appConfig.config.enrollmentAppId)
        .set('AppSecret', this.appConfig.config.enrollmentAppSecret)
        .set('Content-Type', 'application/json')
    };
  }

  private simulateCheckIfExistingHttpCall(lastName: string) {
    if (lastName === 'FAIL') {
      return throwError(new HttpErrorResponse({ status: 409 }));
    } else if (lastName === 'UNKNOWN') {
      return throwError(new HttpErrorResponse({ status: 500 }));
    } else {
      return of(true);
    }
  }

  private simulatePaymentHttpCall(firstName: string) {
    if (firstName === 'FAIL') {
      return throwError(new HttpErrorResponse({ status: 500 }));
    }

    console.log('Delaying payment response');
    return of(null).pipe(delay(3000));
  }
}
