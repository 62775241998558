import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appZipFormat]'
})
export class ZipFormatDirective {
  private _zipControl: AbstractControl;

  constructor(private elementRef: ElementRef) { }

  @Input()
  set zipControl(control: AbstractControl) {
    this._zipControl = control;
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(value: any) {
    if (value !== null) {
      this.onInputChange(value);
    }
  }

  // ZIP Code value should match xxxxx or xxxxx-xxxx
  onInputChange(value: string) {
    if (!value) {
      return value;
    }

    let start = this.elementRef.nativeElement.selectionStart;
    const end = this.elementRef.nativeElement.selectionEnd;
    let newVal = value.replace(/\D/g, '');

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 5) {
      newVal = newVal.replace(/^(\d{0,5})/, '$1');
    } else {
      newVal = newVal.substring(0, 9);
      newVal = newVal.replace(/^(\d{0,5})(\d{0,4})/, '$1-$2');
    }

    if (value !== newVal) {
      this._zipControl.setValue(newVal, { emitEvent: false });
    }

    if ((value.length < newVal.length && start === end) || ((start === 4) || start === 8) && value.length !== newVal.length) {
      if (start > 3) {
        start = start + 1;
      }
      if (start > 7) {
        start = start + 1;
      }
    }
    this.elementRef.nativeElement.setSelectionRange(start, start);
  }
}
